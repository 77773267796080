import api from '@helpers/api'

class HelperService {
  async apiStatus() {
    const response = await api.get('/health')
    return response.data
  }

  async getMaritalStatusAll() {
    const response = await api.get('/marital_status')
    return response.data
  }

  async getCountryAll() {
    const response = await api.get('/country')
    return response.data
  }

  async getHousingTypeAll() {
    const response = await api.get('/housing_type')
    return response.data
  }

  async getWorkingConditionAll() {
    const response = await api.get('/working_condition')
    return response.data
  }

  async getJobTitleAll() {
    const response = await api.get('/job_title')
    return response.data
  }

  async getGradeInstructionAll() {
    const response = await api.get('/grade_instruction')
    return response.data
  }

  async getProfessionAll() {
    const response = await api.get('/profession')
    return response.data
  }

  async getEconomicActivityAll() {
    const response = await api.get('/economic_activity')
    return response.data
  }
}

export default new HelperService()
